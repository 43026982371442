<template>
  <v-layout column>
    <CoreHeader :title="isValidPost ? post.title : 'No Post Found'" />
    <CoreBody>
      <v-sheet
        class="px-5 mx-auto py-4"
        style="max-width: 1000px;"
        :class="isLoading ? 'body-loading' : ''"
      >
        <v-progress-circular
          v-if="isLoading"
          color="secondary"
          indeterminate
          width="2"
          :size="150"
        />

        <template v-else>
          <PostBody v-if="isValidPost" :post="post" />
          <NoPost v-else />
        </template>
      </v-sheet>
    </CoreBody>
  </v-layout>
</template>

<script>
export default {
  name: 'blog-post',
  components: {
    CoreBody: () => import('@/components/core/Body'),
    CoreHeader: () => import('@/components/core/Header'),
    PostBody: () => import('@/components/blog/Body'),
    NoPost: () => import('@/components/blog/404')
  },
  props: {
    friendlyurl: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isLoading: true,
    isValidPost: true,
    post: {
      summary: 'No post found',
      title: '',
      markdown: '',
      date: null,
      tags: []
    }
  }),
  methods: {
    async fetchPost() {
      try {
        const response = await this.$prismic.client.query(
          this.$prismic.Predicates.fulltext('my.blog_post.friendlyurl', this.friendlyurl)
        )
        if (response.results.length) {
          // Found post
          const { data, first_publication_date } = response.results[0]
          this.post = Object.assign({}, this.post, {
            summary: data.summary[0].text,
            title: data.title[0].text,
            markdown: (data.markdown[0] || {}).text,
            date: new Date(first_publication_date),
            tags: []
          })
        } else {
          // No post
          this.isValidPost = false
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
        window.scrollTo(0, 0)
      }
    },
    createMetaDescription() {
      const metaTag = document.createElement('meta')
      metaTag.setAttribute('name', 'description')
      metaTag.setAttribute('content', this.post.summary)
      // Mark tag for deletion by router on route change
      metaTag.setAttribute('data-vue-router-controlled', '')
      document.head.appendChild(metaTag)
    }
  },
  async created() {
    await this.fetchPost()
    this.createMetaDescription()
  }
}
</script>

<style scoped>
.body-loading {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
</style>